// Code block component to render code blocks in markdown files
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { Document } from '@contentful/rich-text-types';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { graphql } from 'gatsby';

interface CodeBlockProps {
  rte: Document;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ rte }) => {
  const plainText = rte ? documentToPlainTextString(rte) : '';

  if (!plainText) {
    return null;
  }

  const sanitizedHtml = sanitizeHtml(plainText, {
    allowedTags: false, // Allow all tags
    disallowedTags: [
      'script',
      'iframe',
      'object',
      'embed',
      'link',
      'meta',
      'form',
    ], // Explicitly disallow these tags
    allowedAttributes: {
      '*': ['style'],
    },
  });
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

export default CodeBlock;

export const codeBlockFragment = graphql`
  fragment CodeBlockFragment on Node {
    ... on ContentfulCodeBlock {
      rte {
        json
      }
    }
  }
`;
