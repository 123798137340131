import React from 'react';
import { graphql } from 'gatsby';
import TabbedGalleryV1 from './tabbedGalleryV1';
import TabbedGalleryV2 from './tabbedGalleryV2';

export const tabbedGalleryFragment = graphql`
  fragment TabbedGalleryFragment on Node {
    ... on ContentfulTabbedGallery {
      componentVersion
      title
      subtitle {
        json
      }
      sectionId
      buttonText
      buttonUrl
      backgroundColor
      showCaptions
      tabs {
        id
        title
        underlineColor
        description
        icon {
          file {
            contentType
            url
          }
        }
        images {
          description
          fluid(maxWidth: 830) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            url
          }
        }
      }
    }
  }
`;

const TabbedGallery = (data) => {
  const { componentVersion } = data;

  if (componentVersion === 'v2') {
    return <TabbedGalleryV2 {...data} />;
  }

  return <TabbedGalleryV1 {...data} />;
};

export default TabbedGallery;
