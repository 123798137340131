import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import Img from 'gatsby-image';
import forceLayout from 'helpers/forceLayout';
import colorTheme from 'helpers/colorTheme';
import useWindowSize from 'hooks/useWindowSize';
import SmartLink from 'components/smartLink';
import { ReactComponent as LeftCaretIcon } from 'images/icon-caret-left.svg';
import { ReactComponent as RightCaretIcon } from 'images/icon-caret-right.svg';
import styles from './tabbedGalleryV1.module.scss';
import RichText from 'components/richText';

const isClient = typeof window === 'object';

export default function TabbedGalleryV1({
  title,
  subtitle,
  buttonText,
  buttonUrl,
  tabs,
  backgroundColor,
  showCaptions,
  sectionId,
}) {
  const windowSize = useWindowSize();
  const [isPaused, setIsPaused] = useState(true);
  const [containerRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const [panelRef, panelInView] = useInView({ threshold: 0.3 });
  const intervalRef = useRef(null);
  const innerRef = useRef(null);
  const [panelIsVisible, setPanelIsVisible] = useState(false);
  const initialPanel = tabs[0]?.images?.length && tabs[0].images[0];
  const [state, setState] = useState({
    panel: 0,
    slide: 0,
    src: `${initialPanel?.file?.url}?w=2200`,
    caption: tabs[0]?.description || initialPanel?.description,
  });
  const isVertical = windowSize.width < 1000;

  /*
   *   Go to panel/slide
   */
  const goToSlide = useCallback(
    function (panelIndex, slideIndex = 0) {
      setPanelIsVisible(false);
      setState({
        panel: panelIndex,
        slide: slideIndex,
        src: `${tabs[panelIndex].images[slideIndex].file.url}?w=2200`,
        caption:
          tabs[panelIndex].description ||
          tabs[panelIndex].images[slideIndex].description,
      });
    },
    [setState, tabs]
  );

  /*
   *   Advance to next image
   */
  const advance = useCallback(
    function () {
      if (!panelInView) return;
      const panelImages = tabs[state.panel].images;
      setPanelIsVisible(false);

      // At the end -> start from 0
      if (
        state.slide >= panelImages.length - 1 &&
        state.panel >= tabs.length - 1
      ) {
        goToSlide(0);

        // No more images in this panel? Go to next panel
      } else if (state.slide + 1 > panelImages.length - 1) {
        goToSlide(state.panel + 1);

        // Go to next image in panel
      } else {
        goToSlide(state.panel, state.slide + 1);
      }
    },
    [goToSlide, panelInView, state, tabs]
  );

  /*
   *   Retreat to previous panel
   */
  const retreat = useCallback(
    function (event) {
      if (!panelInView || (state.panel <= 0 && state.slide <= 0)) return;
      setPanelIsVisible(false);
      event.currentTarget.toggleAttribute('aria-pressed');

      // No more images in this panel? Go to previous panel
      if (state.slide <= 0) {
        goToSlide(state.panel - 1, tabs[state.panel - 1].images.length - 1);

        // Go to previous image in panel
      } else {
        goToSlide(state.panel, state.slide - 1);
      }
    },
    [goToSlide, panelInView, state, tabs]
  );

  /*
   *   Use Effect
   */
  useEffect(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (!isPaused && !isVertical) advance();
    }, 4000);
    return () => clearInterval(intervalRef.current);
  }, [tabs, advance, isPaused, isVertical]);

  /*
   *   Use layout effect
   */
  useLayoutEffect(() => {
    if (panelIsVisible) return;
    forceLayout();
    const raf = requestAnimationFrame(() => setPanelIsVisible(true));

    return () => cancelAnimationFrame(raf);
  }, [panelIsVisible]);

  /*
   *   Tab els
   */
  const tabEls = tabs.map((tab, index) => {
    const icons = tab.icon;
    const isCurrent = state.panel === index;
    return (
      <button
        key={tab.id}
        onClick={() => {
          if (
            state.panel === index &&
            state.slide + 1 <= tabs[state.panel].images.length - 1
          ) {
            advance();
          } else if (state.panel !== index) {
            goToSlide(index);
          }
        }}
        className={classnames(styles.tab, styles[tab.underlineColor], {
          [styles.current]: isCurrent,
        })}
      >
        <div className={styles.icon}>
          <div
            className={styles.icon_grey}
            style={
              icons &&
              (icons[0] ? { backgroundImage: `url(${icons[0].file.url})` } : {})
            }
          />
          {icons && icons[1] && icons[1].file && (
            <div
              className={styles.icon_color}
              style={{
                backgroundImage: `url(${icons[1].file.url})`,
              }}
            />
          )}
        </div>
        <div className={styles.tab__title}>{tab.title}</div>
        <div
          style={colorTheme('background-color', backgroundColor, 'dark')}
          className={styles.tab__border}
        ></div>
      </button>
    );
  });

  /*
   *   Images
   */
  const allImages = tabs.reduce((acc, tab, tabIndex) => {
    const tabSlides = tab.images
      ? tab.images.map((image, index) =>
          state.panel === tabIndex && state.slide === index ? (
            <>
              {image.fluid ? (
                <Img
                  alt=""
                  key={image}
                  className={styles.panel__image}
                  fluid={image.fluid}
                />
              ) : (
                <img
                  className={styles.panel__image}
                  src={image.file.url}
                  loading="lazy"
                  alt=""
                />
              )}
            </>
          ) : null
        )
      : [];
    return [...tabSlides, ...acc];
  }, []);

  /*
   *   Scroll through tabs as we advance if window is too narrow
   */
  const layoutWidth = isClient
    ? getComputedStyle(document.documentElement)
        .getPropertyValue('--layout-width')
        .replace('px', '')
    : 0;
  const innerWidth = innerRef.current ? innerRef.current.offsetWidth : 0;
  const tabWidth =
    layoutWidth / tabs.length - (10 * (tabs.length - 1)) / tabs.length;
  const tabsThatFitInWindow = Math.floor(innerWidth / tabWidth);
  const tabOffset = Math.min(state.panel, tabs.length - tabsThatFitInWindow);
  let mobileOffset = innerWidth < 500 ? (innerWidth - tabWidth) / 2 : 0;
  if (state.panel === 0) mobileOffset = 0;
  if (state.panel === tabs.length - 1)
    mobileOffset = innerWidth < 500 ? innerWidth - tabWidth : 0;

  const tabStyles =
    innerWidth < layoutWidth && isVertical
      ? {
          transform: `translate3d(${
            -tabWidth * tabOffset - tabOffset * 10 + mobileOffset
          }px,0,0)`,
        }
      : null;

  /*
   *   Return
   */
  return (
    <section
      aria-label="Carousel"
      id={sectionId}
      ref={containerRef}
      style={colorTheme('background-color', backgroundColor)}
      className={classnames(
        styles.container,
        styles[isVertical ? 'vertical' : 'horizontal'],
        {
          [styles.revealed]: inView,
        }
      )}
    >
      <h2
        className={classnames(styles.title, {
          [styles.withSubtitle]: subtitle,
        })}
      >
        {title}
      </h2>
      {subtitle && (
        <h3 className={styles.subtitle}>
          <RichText content={subtitle.json} />
        </h3>
      )}
      <div
        onMouseOver={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(true)}
      >
        {showCaptions && (
          <div
            className={classnames(styles.caption, {
              [styles.has_pagination]: tabs[state.panel].images.length > 1,
            })}
          >
            <button
              className={styles.control_button}
              disabled={state.panel <= 0 && state.slide <= 0}
              onClick={retreat}
              aria-label="Previous slide"
            >
              <LeftCaretIcon />
            </button>
            <div className={styles.caption__text}>{state.caption}</div>
            {tabs[state.panel].images.length > 1 && (
              <div className={styles.pagination}>
                {state.slide + 1} / {tabs[state.panel].images.length}
              </div>
            )}
            <button
              className={styles.control_button}
              disabled={
                state.panel >= tabs.length - 1 &&
                state.slide >= tabs[state.panel].images.length - 1
              }
              onClick={advance}
              aria-label="Next slide"
            >
              <RightCaretIcon />
            </button>
          </div>
        )}
        <div ref={innerRef} className={styles.inner}>
          <div className={styles.tabs} style={tabStyles}>
            {tabEls}
            {buttonUrl && !showCaptions && !isVertical && (
              <SmartLink className={styles.tab_cta} to={buttonUrl}>
                {buttonText}
              </SmartLink>
            )}
          </div>
          <div
            ref={panelRef}
            className={classnames(styles.panel, {
              [styles.visible]: panelIsVisible,
            })}
            onClick={advance}
          >
            {allImages}
          </div>
        </div>
      </div>
    </section>
  );
}
