import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SmartLink from 'components/smartLink';

import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as Arrow } from 'images/icon-white-arrow.svg';
import { ReactComponent as TwitterIcon } from 'images/icon-twitter-white.svg';
import { ReactComponent as FacebookIcon } from 'images/icon-facebook-white.svg';
import { ReactComponent as InstagramIcon } from 'images/icon-instagram-white.svg';
import { ReactComponent as YoutubeIcon } from 'images/icon-youtube-white.svg';
import { ReactComponent as LinkedinIcon } from 'images/icon-linkedin-white.svg';

import styles from './footer.module.scss';

export default function Footer() {
  /*
   *   Query main nav items
   */
  const navData = useStaticQuery(graphql`
    query FooterNavQuery {
      contentfulNavigationMenu(contentful_id: { eq: "dpDFtU5h6Wh5GCdaE3fM3" }) {
        links {
          id
          title
          url
          sublinks {
            id
            title
            url
          }
        }
      }
    }
  `);

  /*
   *   Nav items
   */
  const navItems = useMemo(
    () =>
      navData?.contentfulNavigationMenu?.links?.map((link) => (
        <div className={styles.nav_column} key={link.id}>
          <h2 className={styles.nav_title}>{link.title}</h2>
          {link?.sublinks && (
            <ul>
              {link.sublinks.map((sublink) => (
                <li>
                  <SmartLink
                    className={styles.nav_link}
                    key={sublink.id}
                    to={sublink.url}
                  >
                    {sublink.title}
                  </SmartLink>
                </li>
              ))}
            </ul>
          )}
        </div>
      )),
    [navData?.contentfulNavigationMenu?.links]
  );

  return (
    <footer className={styles.container}>
      <section className={styles.logo_section}>
        <Logo
          className={styles.logo}
          title="Newsela logo"
          role="img"
          aria-label="Newsela logo"
        />
        <div className={styles.right_menu}>
          <a
            href="/join"
            data-qa-selector="unauth_footer_sign_up"
            className={styles.right_menu_link}
            aria-label="Sign up"
          >
            Sign up
          </a>
          <a
            href="/signin"
            data-qa-selector="unauth_footer_sign_in"
            className={styles.right_menu_link}
            aria-label="Log In"
          >
            <span>Log in</span>
            <Arrow
              title="icon arrow"
              role="img"
              aria-label="icon arrow"
              className={styles.arrow}
            />
          </a>
        </div>
      </section>

      <div className={styles.inner}>
        <div className={styles.nav}>{navItems}</div>
        <div className={styles.footer_footer}>
          <div className={styles.newselaInc}>
            {new Date().getFullYear()} Newsela Inc.
          </div>

          <div className={styles.legal}>
            <SmartLink to="/terms" className={styles.footer_link}>
              Terms
            </SmartLink>
            <SmartLink
              to="/newsela-privacy-policy"
              className={styles.footer_link}
            >
              Privacy
            </SmartLink>
            <SmartLink
              to="https://newsela.com/about/california-privacy-policy/"
              className={styles.footer_link}
            >
              CA Privacy Notice
            </SmartLink>
            <SmartLink
              to="https://newsela.com/about/cookie-policy/"
              className={styles.footer_link}
            >
              Cookie Policy
            </SmartLink>
            <SmartLink
              to="https://support.newsela.com/article/newsela-accessibility-statement/"
              className={styles.footer_link}
            >
              Accessibility
            </SmartLink>
          </div>

          <div className={styles.social}>
            <SmartLink
              to="https://twitter.com/Newsela"
              className={styles.footer_link}
              aria-label="Twitter"
            >
              <TwitterIcon />
            </SmartLink>
            <SmartLink
              to="https://www.facebook.com/Newsela/"
              className={styles.footer_link}
              aria-label="Facebook"
            >
              <FacebookIcon />
            </SmartLink>
            <SmartLink
              to="https://www.instagram.com/newsela"
              className={styles.footer_link}
              aria-label="Instagram"
            >
              <InstagramIcon />
            </SmartLink>
            <SmartLink
              to="https://www.youtube.com/user/Newsela/videos"
              className={styles.footer_link}
              aria-label="YouTube"
            >
              <YoutubeIcon />
            </SmartLink>
            <SmartLink
              to="https://www.linkedin.com/company/newsela"
              className={styles.footer_link}
              aria-label="LinkedIn"
            >
              <LinkedinIcon />
            </SmartLink>
          </div>
        </div>
      </div>
    </footer>
  );
}
