import React, { useState, useEffect, useRef, cloneElement } from 'react';
import classnames from 'classnames';
import Mousetrap from 'mousetrap';
import SmartLink from 'components/smartLink';
import { ReactComponent as CaretIcon } from 'images/icon-caret-down-alt.svg';
import styles from './mainNav.module.scss';

/*
 *   Subnav link
 */
function SubNavItem({ el, hasFocus }) {
  const ref = useRef(null);

  useEffect(() => {
    if (hasFocus) ref.current.focus();
  }, [hasFocus]);

  return cloneElement(el, { linkRef: ref });
}

/*
 *   Nav Item
 */
export function NavItem({
  to,
  key,
  label,
  accordion,
  children,
  forceClose,
  onClick,
  isSelected,
  onSelectedNavItem,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const caretRef = useRef(null);
  const isOpenRef = useRef(false);
  const currentFocusRef = useRef(-1);
  const [currentFocus, setCurrentFocus] = useState(-1);

  useEffect(() => {
    if (!isSelected) {
      setIsOpen(false);
    }
  }, [isSelected]);

  function open(evt) {
    if (evt) evt.preventDefault();
    setIsOpen(true);
    currentFocusRef.current++;
    setCurrentFocus(currentFocusRef.current);
    Mousetrap.bind(['tab', 'down', 'enter'], handleDownArrow);
    Mousetrap.bind(['shift-tab', 'up'], handleUpArrow);
    Mousetrap.bind(['esc'], handleUpArrow);
  }

  function close() {
    currentFocusRef.current = -1;
    setCurrentFocus(currentFocusRef.current);
    setIsOpen(false);
    Mousetrap.unbind(['tab', 'shift+tab', 'up', 'down', 'esc']);
  }

  function toggle() {
    onSelectedNavItem(key);
    if (accordion) {
      setIsOpen(!isOpen);
    } else {
      if (!isOpenRef.current) {
        open();
      } else {
        close();
      }
    }
  }

  function handleDownArrow(e) {
    e.preventDefault();

    if (!isOpenRef.current) {
      open();
    } else if (currentFocusRef.current + 1 < children.length) {
      currentFocusRef.current++;
      setCurrentFocus(currentFocusRef.current);
    } else {
      close();
    }
  }

  function handleUpArrow(e) {
    e.preventDefault();

    if (currentFocusRef.current < 1) {
      close();
    } else {
      currentFocusRef.current--;
      setCurrentFocus(currentFocusRef.current);
      //sublinkRefs[--currentFocusRef.current].current.focus();
    }
  }

  function handleCaretFocus() {
    Mousetrap.bind(['enter', 'space'], open);
    Mousetrap.bind(['enter', 'down'], handleDownArrow);
  }

  function handleCaretBlur() {
    Mousetrap.unbind(['enter', 'space']);

    if (!isOpenRef.current) Mousetrap.unbind(['enter', 'tab', 'down']);
  }

  /*
   *   Use Effect
   */
  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    return () => {
      Mousetrap.unbind(['tab', 'shift+tab', 'up', 'enter', 'space', 'esc']);
    };
  }, []);

  useEffect(close, [forceClose]);

  useEffect(() => {
    const locationMenuLink = Array.from(
      document.querySelectorAll('main header nav > div')
    ).find((div) => div.textContent.startsWith('Choose'));

    if (locationMenuLink) {
      locationMenuLink.classList.add('location');

      locationMenuLink.addEventListener('click', (event) => {
        const text = getShortStateName(event.target.textContent);
        localStorage.setItem('state', text);
        locationMenuLink.querySelector('a').textContent = text;
      });

      const state = localStorage.getItem('state');

      if (state && state !== 'undefined') {
        locationMenuLink.querySelector('a').textContent = state;
      } else {
        locationMenuLink.querySelector('a').textContent = 'Choose your state';
      }
    }
  });

  function getShortStateName(state) {
    return {
      Alabama: 'AL',
      Alaska: 'AK',
      Arizona: 'AZ',
      Arkansas: 'AR',
      California: 'CA',
      Colorado: 'CO',
      Connecticut: 'CT',
      Delaware: 'DE',
      Florida: 'FL',
      Georgia: 'GA',
      Hawaii: 'HI',
      Idaho: 'ID',
      Illinois: 'IL',
      Indiana: 'IN',
      Iowa: 'IA',
      Kansas: 'KS',
      Kentucky: 'KY',
      Louisiana: 'LA',
      Maine: 'ME',
      Maryland: 'MD',
      Massachusetts: 'MA',
      Michigan: 'MI',
      Minnesota: 'MN',
      Missouri: 'MO',
      Montana: 'MT',
      Nebraska: 'NE',
      Nevada: 'NV',
      'New Hampshire': 'NH',
      'New Jersey': 'NJ',
      'New Mexico': 'NM',
      'New York': 'NY',
      'North Carolina': 'NC',
      'North Dakota': 'ND',
      Ohio: 'OH',
      Oklahoma: 'OK',
      Oregon: 'OR',
      Pennsylvania: 'PA',
      'Rhode Island': 'RI',
      'South Carolina': 'SC',
      'South Dakota': 'SD',
      Tennessee: 'TN',
      Texas: 'TX',
      Utah: 'UT',
      Vermont: 'VT',
      Virginia: 'VA',
      Washington: 'WA',
      'West Virginia': 'WV',
      Wisconsin: 'WI',
      Wyoming: 'WY',
    }[state];
  }

  if (children && children.length) {
    const refedChildren = children.map((child, i) => (
      <SubNavItem key={i} hasFocus={currentFocus === i} el={child} />
    ));

    const linkEl =
      accordion || !to ? (
        <div className={styles.menu__link}>{label}</div>
      ) : (
        <SmartLink to={to} className={styles.menu__link}>
          {label}
        </SmartLink>
      );

    return (
      <div
        className={classnames(styles.menu__parent, {
          [styles.is_open]: isOpen,
          [styles.accordion]: accordion,
        })}
      >
        <div className={styles.menu__item}>
          <button
            ref={caretRef}
            className={styles.menu__caret}
            onFocus={handleCaretFocus}
            onBlur={handleCaretBlur}
            aria-label={`${label} dropdown`}
            onClick={toggle}
            aria-expanded={isOpen}
          >
            {linkEl}
            <CaretIcon title="Open dropdown" />
          </button>

          <div className={styles.dropdown}>{refedChildren}</div>
        </div>
      </div>
    );
  } else if (label) {
    return (
      <SmartLink
        onClick={onClick}
        to={to}
        className={classnames(styles.menu__link, {
          [styles.accordion]: accordion,
        })}
      >
        {label}
      </SmartLink>
    );
  } else {
    return (
      <div className={classnames(styles.custom_menu_link)}>{children}</div>
    );
  }
}

/*
 *   Dropdown item
 */
export function NavDropdownItem({ to, children, linkRef, onClick }) {
  return (
    <SmartLink
      ref={linkRef}
      to={to}
      className={styles.dropdown__item}
      onClick={onClick}
    >
      {children}
    </SmartLink>
  );
}
